<template>
  <div>
    <TipoTicketEdit
      :item="currentItemTipoTicket"
      :update="updateTipoTicket"
      :close="closeTipoTicketEdit"
      :descSingular="descricaoSingularAcao('ADMIN_SAC', 'SAC_TIPO_TICKET')"
      :tipoTicketMenu="tipoTicketMenu"
      :tipoTicketConfiguracao="tipoTicketConfiguracao"
      :getDescricaoAcessoSistema="getDescricaoAcessoSistema"
    ></TipoTicketEdit>
    <TipoTicketEtapaEdit
      :item="currentItemTipoTicketEtapa"
      :update="updateTipoTicketEtapa"
      :close="closeTipoTicketEtapaEdit"
      :descSingular="descricaoSingularAcao('ADMIN_SAC', 'SAC_TIPO_TICKET_ETAPA')"
      :tipoTicket="tipoTicket"
      :tipoTicketEtapaRequerimento="tipoTicketEtapaRequerimento"
      :tipoTicketEtapaRequerimentoId="tipoTicketEtapaRequerimentoId"
      :setores="setores"
    ></TipoTicketEtapaEdit>
    <TicketAcaoObjetoEdit
      :item="currentItemTicketAcaoObjeto"
      :update="updateTicketAcaoObjeto"
      :close="closeTicketAcaoObjetoEdit"
      :ticketAcaoObjetoEvento="ticketAcaoObjetoEvento"
      :ticketAcaoRegraColuna="ticketAcaoRegraColuna"
      :ticketAcaoRegraCondicao="ticketAcaoRegraCondicao"
      :ticketAcaoExecucaoAcao="ticketAcaoExecucaoAcao"
      :ticketAcaoExecucaoTipo="ticketAcaoExecucaoTipo"
    ></TicketAcaoObjetoEdit>
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab v-if="loadAuthorizationAction('ADMIN_SAC', 'SAC_TIPO_TICKET')" 
            :title="descricaoPluralAcao('ADMIN_SAC', 'SAC_TIPO_TICKET')">
        <TipoTicketList :items="tipoTicket" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirmTipoTicket="deleteConfirmTipoTicket" :tipoTicketMenu="tipoTicketMenu"/>
      </CTab>
      <CTab v-if="loadAuthorizationAction('ADMIN_SAC', 'SAC_TIPO_TICKET_ETAPA')" 
            :title="descricaoPluralAcao('ADMIN_SAC', 'SAC_TIPO_TICKET_ETAPA')">
        <TipoTicketEtapaList :items="tipoTicketEtapa" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirmTipoTicketEtapa="deleteConfirmTipoTicketEtapa" :tipoTicket="tipoTicket"/>
      </CTab>
      <CTab v-if="loadAuthorizationAction('ADMIN_SAC', 'SAC_TICKET_ACAO_OBJETO')" 
            :title="descricaoPluralAcao('ADMIN_SAC', 'SAC_TICKET_ACAO_OBJETO')">
        <TicketAcaoObjetoList :items="ticketAcaoObjeto" :openEdit="openEdit" :openCreate="openCreate" :deleteConfirmTicketAcaoObjeto="deleteConfirmTicketAcaoObjeto"/>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone                    from 'just-clone';
import { get, post, put, del }  from '@/helpers/apiRequest';
import {
  loadAuthorizationAction,
  descricaoPluralAcao,
  descricaoSingularAcao,
  getDescricaoAcessoSistema
}                               from '@/helpers/cargas';
import TipoTicketList           from './components/TipoTicket/TipoTicketList.vue';
import TipoTicketEdit           from './components/TipoTicket/TipoTicketEdit.vue';
import TipoTicketEtapaList      from './components/TipoTicketEtapa/TipoTicketEtapaList.vue';
import TipoTicketEtapaEdit      from './components/TipoTicketEtapa/TipoTicketEtapaEdit.vue';
import TicketAcaoObjetoList     from './components/TicketAcaoObjeto/TicketAcaoObjetoList.vue';
import TicketAcaoObjetoEdit     from './components/TicketAcaoObjeto/TicketAcaoObjetoEdit.vue';
import _filter                  from 'lodash/filter';

const TipoTicketItem = {
  id                    : null,
  chave                 : null,
  desc                  : null,
  usa_transportadora    : 0,
  usa_produto           : 0,
  destaca               : 0,
  usa_consumidor_final  : 0,
  menu                  : null,
  usa_mensagem          : 0,
  usa_nota              : 0,
  usa_nota_produto      : 0,
  usa_prorrogacao       : 0,
  acessab2b             : 1,
  acessacallcenter      : 1,
  acessafv              : 1
}

const TipoTicketEtapaItem = {
  id                    : null,
  tipo_ticket_id        : null,
  chave                 : null,
  desc                  : null,
  sla_maximo            : null,
  permite_finalizar     : null,
  permite_retroceder    : null,
  ordem                 : null,
  aguarda_cliente       : 0,
  requerimento          : null,
  requerimento_id       : null,
  permite_avancar       : 1,
  observacao            : null,
  final                 : 0,
  setor_id              : null,
  permite_custos        : 0
}

const TicketAcaoObjeto = {
  id                    : null,
  ativo                 : null,  
  ordem                 : null,
  nome                  : null,
  evento                : null,
  validar_todas_regras  : null,
  regras                : [],
  acoes                 : []
}

export default {
  name: 'sac',
  components: {
    TipoTicketList,
    TipoTicketEdit,
    TipoTicketEtapaList,
    TipoTicketEtapaEdit,
    TicketAcaoObjetoList,
    TicketAcaoObjetoEdit
  },
  data() {
    return {
      loading                       : false,
      tipoTicket                    : [],
      tipoTicketMenu                : [],
      tipoTicketConfiguracao        : [],
      tipoTicketEtapa               : [],
      tipoTicketEtapaRequerimento   : [],
      tipoTicketEtapaRequerimentoId : [],
      setores                       : [],
      ticketAcaoObjeto              : [],
      ticketAcaoObjetoEvento        : [],
      ticketAcaoRegraColuna         : [],
      ticketAcaoRegraCondicao       : [],
      ticketAcaoExecucaoAcao        : [],
      ticketAcaoExecucaoTipo        : [],
      currentItemTipoTicket         : {},
      currentItemTipoTicketEtapa    : {},
      currentItemTicketAcaoObjeto   : {},
      currentAccess                 : false
    };
  },
  methods: {
    descricaoPluralAcao,
    descricaoSingularAcao,
    loadAuthorizationAction,
    getDescricaoAcessoSistema,
    loadTipoTicket() {
      this.loading = true;
      get('/admin/sac/tipo-ticket')
        .then((json) => {
          this.tipoTicket  = json;
          this.loading      = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTipoTicketMenu() {
      this.loading = true;
      get('/admin/sac/tipo-ticket/menu')
        .then((json) => {
          this.tipoTicketMenu  = json;
          this.loading         = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTipoTicketConfiguracao() {
      this.loading = true;
      get('/admin/sac/tipo-ticket/configuracao')
        .then((json) => {
          this.tipoTicketConfiguracao = json;
          this.loading                = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTipoTicketEtapa() {
      this.loading = true;
      get('/admin/sac/tipo-ticket-etapa')
        .then((json) => {
          this.tipoTicketEtapa  = json;
          this.loading          = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTipoTicketEtapaRequerimento() {
      this.loading = true;
      get('/admin/sac/tipo-ticket-etapa/requerimento')
        .then((json) => {
          this.tipoTicketEtapaRequerimento = json;
          this.loading                     = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTipoTicketEtapaRequerimentoId() {
      this.loading = true;
      get('/admin/sac/tipo-ticket-etapa/requerimento-id')
        .then((json) => {
          this.tipoTicketEtapaRequerimentoId = json;
          this.loading                       = false;
        })
        .catch(() => (this.loading = false));
    },
    loadSetores() {
      this.loading = true;
      get('/admin/setor-atendimento')
        .then((json) => {
          this.setores = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoObjeto() {
      this.loading = true;
      get('/admin/sac/ticket-acao-objeto')
        .then((json) => {
          this.ticketAcaoObjeto = json;
          this.loading    = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoObjetoEvento() {
      this.loading = true;
      get('/admin/sac/ticket-acao-objeto/evento')
        .then((json) => {
          this.ticketAcaoObjetoEvento = json;
          this.loading                = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoRegraColuna() {
      this.loading = true;
      get('/admin/sac/ticket-acao-regra/coluna')
        .then((json) => {
          this.ticketAcaoRegraColuna = json;
          this.loading               = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoRegraCondicao() {
      this.loading = true;
      get('/admin/sac/ticket-acao-regra/condicao')
        .then((json) => {
          this.ticketAcaoRegraCondicao = json;
          this.loading                 = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoExecucaoAcao() {
      this.loading = true;
      get('/admin/sac/ticket-acao-execucao/acao')
        .then((json) => {
          this.ticketAcaoExecucaoAcao = json;
          this.loading                = false;
        })
        .catch(() => (this.loading = false));
    },
    loadTicketAcaoExecucaoTipo() {
      this.loading = true;
      get('/admin/sac/ticket-acao-execucao/tipo')
        .then((json) => {
          this.ticketAcaoExecucaoTipo = json;
          this.loading                = false;
        })
        .catch(() => (this.loading = false));
    },
    openCreate(modalName) {
      this.currentItemTipoTicket       = clone(TipoTicketItem)     
      this.currentItemTipoTicketEtapa  = clone(TipoTicketEtapaItem)
      this.currentItemTicketAcaoObjeto = clone(TicketAcaoObjeto)
      
      this.$modal.show(modalName);
    },
    closeTipoTicketEdit() {
      this.$modal.hide('tipoTicketEdit');
    },
    closeTipoTicketEtapaEdit() {
      this.$modal.hide('tipoTicketEtapaEdit');
    },
    closeTicketAcaoObjetoEdit() {
      this.$modal.hide('ticketAcaoObjetoEdit');
    },
    openEdit(item, modalName) {
      if (modalName == 'tipoTicketEdit') {
        this.currentItemTipoTicket = clone(item)
      }

      if (modalName == 'tipoTicketEtapaEdit') {
        this.currentItemTipoTicketEtapa = clone(item)
      }

      if (modalName == 'ticketAcaoObjetoEdit') {
        this.currentItemTicketAcaoObjeto = clone(item)
      }

      this.loading     = false;
      this.$modal.show(modalName);
    },
    doRequestTipoTicket() {      
      return this.currentItemTipoTicket.id ?
      put(`/admin/sac/tipo-ticket/${this.currentItemTipoTicket.id}`, this.currentItemTipoTicket) :
      post('/admin/sac/tipo-ticket', this.currentItemTipoTicket);
    },
    updateTipoTicket() {
      this.doRequestTipoTicket()
      .then((json) => {
        this.closeTipoTicketEdit();
        this.loadTipoTicket();
        this.$swal({
          title: 'Salvo com sucesso',
          text: json.message,
          showCancelButton: false,
          cancelButtonText: 'Fechar',
          showConfirmButton: true,
          icon: 'success',
        });          
      })
      .catch((error) => {
        this.$swal({
          title: 'Falha ao Salvar',
          text: error.message,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          showConfirmButton: false,
          icon: 'error',
        });
      });
    },
    deleteConfirmTipoTicket(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/sac/tipo-ticket/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: json.message,
                  icon: 'success',
                });
                this.loadTipoTicket();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: err.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    doRequestTipoTicketEtapa() {      
      return this.currentItemTipoTicketEtapa.id ?
      put(`/admin/sac/tipo-ticket-etapa/${this.currentItemTipoTicketEtapa.id}`, this.currentItemTipoTicketEtapa) :
      post('/admin/sac/tipo-ticket-etapa', this.currentItemTipoTicketEtapa);
    },
    updateTipoTicketEtapa() {
      this.doRequestTipoTicketEtapa()
      .then((json) => {
        this.closeTipoTicketEtapaEdit();
        this.loadTipoTicketEtapa();
        this.$swal({
          title: 'Salvo com sucesso',
          text: json.message,
          showCancelButton: false,
          cancelButtonText: 'Fechar',
          showConfirmButton: true,
          icon: 'success',
        });          
      })
      .catch((error) => {
        this.$swal({
          title: 'Falha ao Salvar',
          text: error.message,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          showConfirmButton: false,
          icon: 'error',
        });
      });
    },
    deleteConfirmTipoTicketEtapa(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/sac/tipo-ticket-etapa/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: json.message,
                  icon: 'success',
                });
                this.loadTipoTicketEtapa();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: err.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
    doRequestTicketAcaoObjeto() {      
      return this.currentItemTicketAcaoObjeto.id ?
      put(`/admin/sac/ticket-acao-objeto/${this.currentItemTicketAcaoObjeto.id}`, this.currentItemTicketAcaoObjeto) :
      post('/admin/sac/ticket-acao-objeto', this.currentItemTicketAcaoObjeto);
    },
    updateTicketAcaoObjeto() {
      this.doRequestTicketAcaoObjeto()
      .then((json) => {
        this.closeTicketAcaoObjetoEdit();
        this.loadTicketAcaoObjeto();
        this.$swal({
          title: 'Salvo com sucesso',
          text: json.message,
          showCancelButton: false,
          cancelButtonText: 'Fechar',
          showConfirmButton: true,
          icon: 'success',
        });          
      })
      .catch((error) => {
        this.$swal({
          title: 'Falha ao Salvar',
          text: error.message,
          showCancelButton: true,
          cancelButtonText: 'Fechar',
          showConfirmButton: false,
          icon: 'error',
        });
      });
    },
    deleteConfirmTicketAcaoObjeto(id) {
      this.$swal({
        title: 'Confirma Remover ?',
        text: 'Essa ação não poderá ser desfeita',
        showCancelButton: true,
        icon: 'warning',
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
      })
        .then((res) => {
          if (res.value) {
            this.loading = true;
            del(`/admin/sac/ticket-acao-objeto/${id}`)
              .then((json) => {
                this.loading = false;
                this.$swal({
                  title: json.message,
                  icon: 'success',
                });
                this.loadTicketAcaoObjeto();
              })
              .catch((err) => {
                this.loading = false;
                this.$swal({
                  title: err.message,
                  icon: 'error',
                });
              });
          }
        })
        .catch(() => {});
    },
  },
  beforeMount() {
    this.loading = true;
    this.loadTipoTicket();
    this.loadTipoTicketMenu();
    this.loadTipoTicketConfiguracao();
    this.loadTipoTicketEtapa();
    this.loadTipoTicketEtapaRequerimento();
    this.loadTipoTicketEtapaRequerimentoId();
    this.loadSetores();
    this.loadTicketAcaoObjeto();
    this.loadTicketAcaoObjetoEvento();
    this.loadTicketAcaoRegraColuna();
    this.loadTicketAcaoRegraCondicao();
    this.loadTicketAcaoExecucaoAcao();
    this.loadTicketAcaoExecucaoTipo();
  },
};
</script>

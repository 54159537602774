<template>
  <div>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-1">
        <div class="pull-right">
          <button v-on:click="openCreate('ticketAcaoObjetoEdit')" class="button button-success button-block">
            <fa-icon icon="plus" />
            Criar
          </button>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>Evento</th>
            <th>Ordem</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td class="highlight">
              {{ item.id }}
            </td>
            <td>{{ item.nome }}</td>
            <td>
              <span class="label primary">{{ item.evento }}</span>
            </td>
            <td>{{ item.ordem }}</td>
            <td class="actions">
              <button type="button" class="button button-primary" v-on:click="openEdit(item, 'ticketAcaoObjetoEdit')"><fa-icon icon="pencil-alt" /></button>
              <button type="button" name="button" v-on:click="deleteConfirmTicketAcaoObjeto(item.id)" class="button button-error"><fa-icon icon="trash-alt" /></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'list',
  props: ['items' , 'openEdit', 'openCreate', 'deleteConfirmTicketAcaoObjeto']
}
</script>
